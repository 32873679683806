import React, { useState } from 'react';
import Axios from 'axios';
import { useFormState } from '../../../hooks';
import { StateOptions } from '../../../helpers';

import {
  PanelHeading,
  PanelWrapper,
  PanelTitle,
  PanelName,
  PanelIcon,
  PanelDetails,
  PanelEditButton,
  MobilePanelDetails,
} from './PanelStyles';

import {
  FormInput,
  FormItem,
  FormLabel,
  FormSelect,
  SmartPageButton,
  ErrorMessage,
} from '../../../ExpressAppUI';

import { trackPanelSave } from '../../tracking';
import RadioCard from '../../../purchase-bond/components/RadioCard';

function PrincipalName({ bond, ...props }) {
  return (
  <>
    {props.isIndividualPrincipal ? (
      <FormItem>
        <FormLabel>Name</FormLabel>
        {
          (props.errors.firstName && <p className="small-error">{props.errors.firstName}</p>)
          || (props.errors.lastName && <p className="small-error">{props.errors.lastName}</p>)
        }
        <FormInput
          required
          width={'28%'}
          name="firstName"
          placeholder="First"
          defaultValue={props.values.firstName}
          autocomplete="new-password"
          hasError={props.hasError('firstName')}
          onBlur={props.formBlur}
          data-track={'Principal Info - First Name'}
        ></FormInput>
        <FormInput
          width={'27%'}
          margin={'0% 0% 0% 1%'}
          name="middleName"
          placeholder="Middle"
          defaultValue={props.values.middleName}
          autocomplete="new-password"
          hasError={props.hasError('middleName')}
          onBlur={props.formBlur}
          data-track={'Principal Info - Middle Name'}
        ></FormInput>
        <FormInput
          required
          width={'27%'}
          margin={'0% 0% 0% 1%'}
          name="lastName"
          placeholder="Last"
          defaultValue={props.values.lastName}
          autocomplete="new-password"
          hasError={props.hasError('lastName')}
          onBlur={props.formBlur}
          data-track={'Principal Info - Last Name'}
        ></FormInput>
        <FormInput
          width={'15%'}
          margin={'0% 0% 0% 1%'}
          name="suffix"
          placeholder="Suffix"
          defaultValue={props.values.suffix}
          autocomplete="new-password"
          hasError={props.hasError('suffix')}
          onBlur={props.formBlur}
          data-track={'Principal Info - Suffix'}
        ></FormInput>
      </FormItem>
    )
      : (
      <FormItem>
        <FormLabel>Name</FormLabel>
        {props.errors.companyName && <p className="small-error">{props.errors.companyName}</p>}
        <FormInput
          required
          name="companyName"
          placeholder={
            bond.principalType === 'both'
              ? 'Company or Individual Name'
              : bond.principalType === 'individual'
                ? 'Individual Name'
                : 'Company Name'
          }
          defaultValue={props.values.companyName}
          autocomplete="new-password"
          hasError={props.hasError('companyName')}
          onBlur={props.formBlur}
          data-track={'Principal Info - Name'}
        ></FormInput>
        {bond.principalType !== 'individual' && (
          <FormInput
            autocomplete="new-password"
            placeholder="Company DBA"
            name="companyDBA"
            defaultValue={props.values.companyDBA}
            onBlur={props.formBlur}
            data-track={'Principal Info - DBA'}
          ></FormInput>
        )}
      </FormItem>
      )}
  </>
  );
}

function HighLevelPrincipalPanel({
  lead, bond, dispatch, ...props
}) {
  // One of false, "individual", "company"
  const [typeOfPrincipal, setTypeOfPrincipal] = useState(getTypeOfPrincipal(lead, bond));

  if (typeOfPrincipal) {
    // return normal principal
    return <PrincipalPanel lead={lead} bond={bond} dispatch={dispatch} isIndividualPrincipal={typeOfPrincipal === 'individual'} {...props}/>;
  }

  return (
     <PanelWrapper
      active={props.isActive}
      className={`is-active-${props.isActive} instant-purchase-panel`}
    >
      <PanelHeading>
        <PanelTitle>
          <PanelIcon complete={false} icon="building"></PanelIcon>
          <PanelName>Principal Info</PanelName>
        </PanelTitle>
      </PanelHeading>
      {props.isActive && (
        <>
          <p >
            Will you be doing work under an individual or company name?
          </p>
          <RadioCard name="principalType" value="Individual" onChange={() => setTypeOfPrincipal('individual')} ></RadioCard>
          <RadioCard name="principalType" value="Company" onChange={() => setTypeOfPrincipal('company')} ></RadioCard>
        </>
      )}

    </PanelWrapper>
  );
}

function getTypeOfPrincipal(lead, bond) {
  // TX notary bonds require individual principal
  const requiresIndividualPrincipal = [
    '56c4d1411f978f4e7db00916',
    '5963e2eb4572ef077fb67034',
    '5963e0e44572ef077fb66ed6',
    '4edc577f7c03738a9d57bfd1',
    '5963dfbd4572ef077fb66db9',
    '5963e1ef4572ef077fb66f91'
  ].includes(bond.id);

  if (requiresIndividualPrincipal) return 'individual';

  if (lead.individualPrincipal
    && (lead.individualPrincipal.first
    || lead.individualPrincipal.last
    || lead.individualPrincipal.middle
    || lead.individualPrincipal.suffix)
  ) {
    return 'individual';
  } if (!lead.company_name && bond.id.toString() === '5a0c5d317daf2a907f984a3e') {
    // If rock island and there is no principal info already entered, return false
    return false;
  }

  // default to return company
  return 'company';
}

function PrincipalPanel({
  lead, bond, dispatch, isIndividualPrincipal, ...props
}) {
  const [complete, setComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await Axios.post(`/application/${props.nonce}/save-principal-info`, values);
      dispatch({ type: 'UPDATE PRINCIPAL INFO', ...values });
      trackPanelSave('Principal Info');
      setComplete(true);
    } catch {
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const form = React.useRef(null);
  const { isActive } = props;

  // TODO: CN-1498 use company collection from lead starting line 145
  const formattedFields = {
    companyName: lead.company_name,
    companyDBA: lead.company_dba,
    companyAddress: lead.company_address,
    companyAddress2: lead.company_address2,
    companyState: lead.company_state,
    companyZip: lead.company_zip,
    companyCity: lead.company_city,
    firstName: (lead.individualPrincipal && lead.individualPrincipal.first) || '',
    middleName: (lead.individualPrincipal && lead.individualPrincipal.middle) || '',
    lastName: (lead.individualPrincipal && lead.individualPrincipal.last) || '',
    suffix: (lead.individualPrincipal && lead.individualPrincipal.suffix) || '',
  };
  const requiredFields = [
    'companyAddress',
    'companyState',
    'companyZip',
    'companyCity',
  ];

  if (isIndividualPrincipal) {
    requiredFields.push('firstName', 'lastName');
  } else {
    requiredFields.push('companyName');
  }

  // Grab our form state and event handlers
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError },
  ] = useFormState(formattedFields, requiredFields, handleSubmit, form);

  const HelperText = () => {
    if (bond.version === 'v2') {
      if (bond.principalType === 'individual') {
        return (
          <p >
            The principal is the individual that must be bonded. The information
            you provide here will be displayed on the bond form{' '}
            <strong>exactly</strong> as entered.
          </p>
        );
      }
      if (bond.principalType === 'company') {
        return (
          <p>
            The principal is the company that must be bonded.
          </p>
        );
      }
    }
    return (
      <p >
        The Principal is the individual, company, or entity that must be bonded.
      </p>
    );
  };

  return (
    <PanelWrapper
      active={isActive}
      className={`is-active-${isActive} instant-purchase-panel`}
    >
      <PanelHeading>
        <PanelTitle>
          <PanelIcon complete={complete} icon="building"></PanelIcon>
          <PanelName>Principal Info</PanelName>
        </PanelTitle>
        {!isActive && complete ? (
          <>
            <PanelDetails>
              {(isIndividualPrincipal && (
                <div>
                  {(values.firstName && (`${values.firstName} `)) || ''}
                  {(values.middleName && (`${values.middleName} `)) || ''}
                  {(values.lastName && (`${values.lastName} `)) || ''}
                  {(values.suffix && (`${values.suffix} `)) || ''}
                </div>
              )) || (
                <div>
                  {values.companyName}{' '}
                  {values.companyDBA && (
                    <>
                      <strong>DBA </strong> {values.companyDBA}
                    </>
                  )}
                </div>
              )}
              <div>{values.companyAddress}</div>
              <div>{values.companyAddress2}</div>
              {values.companyCity && values.companyState && values.companyZip && (
                <div>
                  {values.companyCity}, {values.companyState}{' '}
                  {values.companyZip}
                </div>
              )}
            </PanelDetails>
            <PanelEditButton
              onClick={() => {
                dispatch({
                  type: 'CHANGE ACTIVE STEP',
                  activePanel: 'Principal',
                });
              }}
            >
              Edit
            </PanelEditButton>
          </>
        ) : null}
      </PanelHeading>
      {!isActive ? (
        <MobilePanelDetails>
          <div>
            {(isIndividualPrincipal && (
              <p>
                {(values.firstName && (`${values.firstName} `)) || ''}
                {(values.middleName && (`${values.middleName} `)) || ''}
                {(values.lastName && (`${values.lastName} `)) || ''}
                {(values.suffix && (`${values.suffix} `)) || ''}
              </p>
            )) || (
              <p>{values.companyName}{' '}
                {values.companyDBA && (
                  <>
                    <strong>DBA </strong> {values.companyDBA}
                  </>
                )}
              </p>
            )}
            <p>{values.companyAddress}</p>
            {values.companyAddress2 && <p>{values.companyAddress2}</p>}
            {values.companyCity && values.companyState && values.companyZip && (
              <p>{values.companyCity} {values.companyState}, {values.companyZip}</p>
            )}
          </div>
        </MobilePanelDetails>
      ) : null}
      {!!isActive && (
        <form action="" ref={form}>
          <HelperText></HelperText>
          <PrincipalName
            isIndividualPrincipal={isIndividualPrincipal}
            bond={bond}
            errors={errors}
            values={values}
            hasError={hasError}
            formBlur={formBlur}
          ></PrincipalName>
          <FormItem>
            <FormLabel>Address</FormLabel>
            {errors.companyAddress && <p className="small-error">{errors.companyAddress}</p>}
            <FormInput
              placeholder="Street Address"
              data-inputtype="address"
              required
              autocomplete="new-password"
              onChange={formBlur}
              name="companyAddress"
              value={values.companyAddress}
              hasError={hasError('companyAddress')}
              onBlur={formBlur}
              data-track={'Principal Info - Address'}
            ></FormInput>
            <FormInput
              placeholder="Suite, Unit, Building, Floor, Etc."
              data-inputtype="address"
              name="companyAddress2"
              autocomplete="new-password"
              onChange={formBlur}
              value={values.companyAddress2}
              hasError={hasError('companyAddress2')}
              onBlur={formBlur}
              data-track={'Principal Info - Address 2'}
            ></FormInput>
            {errors.companyCity || errors.companyZip || errors.companyState ? <p className="small-error">Please enter a valid zipcode, city, and state</p> : ''}
            <FormInput
              width={'30%'}
              name={'companyZip'}
              defaultValue={values.companyZip}
              placeholder="Zip"
              autocomplete="new-password"
              data-inputtype="zip"
              required
              hasError={hasError('companyZip')}
              data-zip
              onBlur={formBlur}
              data-track={'Principal Info - Zip'}

            />
            <FormInput
              width={'48%'}
              margin={'0% 1%'}
              name={'companyCity'}
              placeholder="City"
              defaultValue={values.companyCity}
              autocomplete="new-password"
              required
              data-zip2city
              onChange={formBlur}
              hasError={hasError('companyCity')}
              data-track={'Principal Info - City'}
            />
            <FormSelect
              name={'companyState'}
              defaultValue={values.companyState || ''}
              required
              autocomplete="new-password"
              width={'20%'}
              hasError={hasError('companyState')}
              data-zip2state
              onChange={formBlur}
              data-track={'Principal Info - State'}
            >
              <option value="" disabled>
                State
              </option>
              {StateOptions.map((option) => (
                <option
                  key={`principalState-${option.value}`}
                  value={option.value}
                >
                  {option.display}
                </option>
              ))}
            </FormSelect>
          </FormItem>
          <SmartPageButton
            className='instant-purchase-panel-button'
            buttonText='Save & Continue'
            onClickFunction={submitForm}
            isSubmitting={submitting}
            track={'Principal Info - Save'}
          >
          </SmartPageButton>
          {showError ? (
            <ErrorMessage>
              An error has occurred when trying to save your information. Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a>
            </ErrorMessage>
          ) : null}
        </form>
      )}
    </PanelWrapper>
  );
}

export default HighLevelPrincipalPanel;
