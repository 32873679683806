import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { ErrorText, FormField } from "../common/forms/fields";
import { navigate } from "@reach/router";
import { useQueryClient } from "react-query";
import { useLead, APIClient } from "../client";

import { Loading } from "../purchase-bond/components/Loading";

export async function fetchLicenseInfo(licenseNumber, lead) {
  const bondId = lead?.bond?.application?.suretyBond?._id;
  const state = lead?.bond?.state;
  
  const response = await APIClient('cslb/license-lookup', {
    method: 'POST',
    data: { bondId, state, licenseNumber },
  }).catch(error => {
    console.log(error);
    throw error;
  });

  if (response?.error?.data?.error) {
    const { error: { data: { error } } } = response;

    console.error(error)
    throw new Error(error);
  }

  return response.data;
}

function LicenseForm(props) {
  const { lead } = useLead();
  const queryClient = useQueryClient();
  async function handleSubmit(values, { setFieldError }) {
    // check license number first

    try {
      const licenseInfo = await fetchLicenseInfo(values.licenseNumber, lead);
      queryClient.setQueryData(
        ["licenseNumber", values.licenseNumber],
        licenseInfo
      );
      navigate(`license/${values.licenseNumber}`);
    } catch (error) {
      setFieldError("licenseNumber", error.message);
    }
  }
  const locationState = props?.location?.state
  // const initialValues = locationState?.initialValues || { licenseNumber: "" };
  const initialErrors = locationState?.initialValues?.licenseNumber ? {licenseNumber: `No match found for license number: ${locationState?.initialValues?.licenseNumber}`} : {}
  const initialTouched = initialErrors['licenseNumber'] ? {licenseNumber: true} : {}
  const initialValues = locationState?.initialValues || {licenseNumber: ''}
  const formSchema = Yup.object().shape({
    licenseNumber: Yup.string().trim().required("License Number is required"),
  });

  async function sendToExpressApp(licenseNumber = "") {
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        licenseNumber,
      }),
    };
    try {
      const res = await window.fetch(`send-to-express-app`, opts);
      const data = await res.json();
      window.location = data.url;
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Formik
      validationSchema={formSchema}
      initialValues={initialValues}
      initialTouched={initialTouched}
      initialErrors={initialErrors}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {(props) => {
        const licenseError = props.errors["licenseNumber"];
        const licenseNumber = props.values["licenseNumber"];
        const isSubmitting = props.isSubmitting;
        return (
          <Form style={{ width: "100%" }}>
            <Loading isLoading={isSubmitting}>
              <p style={{fontWeight: 'bolder', fontSize: '16px'}}>
              Please provide your California Contractors State License Board (CSLB) license number OR application fee number.
              </p>
              <hr/>
              <p>
                CSLB license numbers do not exceed 8 digits and do not include letters.
              </p>
            <p>

            Application fee numbers (also known as “receipt numbers”) are 11 digits as provided on the CSLB’s application acknowledgement letter.

            </p>
            <div
                  style={{
                    columnGap: '.5rem',
                    boxShadow: 'rgb(0 0 0 / 8%) 0.1rem 0.2rem 0.2rem',
                    padding: '15px',
                    margin: '1rem 0',
                    borderRadius: '4px',
                    // bg-green-100 or bg-yellow-50
                    backgroundColor: '#fefce8',
                    // text-green-700 or text-yellow-800
                    color: '#854d0e',
                  }}
                >
                If you do not have an existing license number and have not started an application with the CSLB, please do so now and return to apply once you have your personal number.
                  <br/>
                  </div>



              <FormField
                name="licenseNumber"
                label="License number or Application fee number"
                showError={false}
                disabled={isSubmitting}
              />
              {licenseError && (
                <ErrorText style={{ fontSize: "14px" }}>
                  {licenseError?.includes("No match found for license number") ? (
                    <span>
                      No record found for license # {licenseNumber}.
                      <br/>
                      Please check your number and try again or{" "}
                      <button
                        style={{
                          background: 'none',
                          color: '#0d8bd1',
                          fontSize: '14px',
                          display: 'inline',
                          padding: '0',
                          border: 'none',
                          textTransform:'none'
                      }}
                        onClick={() => sendToExpressApp(licenseNumber)}>
                        continue applying for your bond.
                      </button>

                    </span>
                  ) : (
                    <>{licenseError}</>
                  )}
                </ErrorText>
              )}

                <button
                  style={{ marginTop: "10px" }}
                  disabled={isSubmitting || licenseError}
                  type="submit"
                >
                  Continue
                </button>

            </Loading>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LicenseForm;
